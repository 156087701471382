import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { CompleteScreen } from '@asteria/component-alert';

import { Translation, TranslationService } from '@asteria/language';

import '../styles.scss';

/** @type { React.FC<{ from: string, step: number, card: unknown }> } */
const FeedbackSuccess = React.memo(function FeedbackSuccess({ from, card }) {
	return (
		<div className="flex flex-col gap-4">
			<CompleteScreen
				level="success"
				icon="check"
				iconPosition="first"
				title={TranslationService.getV2(['card.content.title'], {
					postfix: {
						variant: 'feedback',
						from: from,
						step: 'success',
					},
				})}
			>
				<div className="p-4">
					<Translation
						className="text-center"
						translationKey="card.content.text"
						translationOptions={{
							postfix: {
								variant: 'feedback',
								from: from,
								step: 'success',
							},
							data: { card: card },
						}}
						Component={Text}
					/>
				</div>
			</CompleteScreen>
		</div>
	);
});

FeedbackSuccess.propTypes = {
	from: PropTypes.string,
	step: PropTypes.number,
	card: PropTypes.object,
};

export default FeedbackSuccess;
