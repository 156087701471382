import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import {
	Text as BaseText,
	Title as BaseTitle,
} from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import TrendIcon from '../../components/trends';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').ConfigValue } ConfigValue
 * @typedef { import('./types').Trends } Trends
 * @typedef { import('./types').TrendsVisibility } TrendsVisibility
 */

function withConfig(Component) {
	/** @type { React.FC<ChildrenOptions & { config: ConfigValue }> } */
	const Wrapper = React.memo(function Wrapper(props) {
		const { config, ...args } = props;

		if (typeof config === 'function') {
			return config(args);
		}

		if (typeof config === 'object') {
			return config;
		}

		return <Component {...props} />;
	});

	Wrapper.propTypes = {
		config: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),
	};

	return Wrapper;
}

/** @type { React.FC<ChildrenOptions & { config: ConfigValue }> } */
const ContentSubtitle = withConfig(function ContentSubtitle(props) {
	const { config, ...args } = props;
	const { translationOptions, loading } = args;

	return (
		<Translation
			translationKey="card.content.subtitle"
			translationOptions={translationOptions}
			show={config}
			showWhenEmpty={false}
			Component={BaseText}
			loading={loading}
		/>
	);
});

ContentSubtitle.propTypes = {
	config: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
		PropTypes.node,
	]),
};

/** @type { React.FC<ChildrenOptions & { config: ConfigValue, trends: { visible: TrendsVisibility, direction: Trends } }> } */
const ContentTitle = withConfig(function ContentTitle(props) {
	const { config, trends, ...args } = props;
	const { translationOptions, loading, query } = args;

	return (
		<Group
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
		>
			<Translation
				translationKey="card.content.title"
				translationOptions={translationOptions}
				show={config}
				showWhenEmpty={false}
				Component={BaseTitle}
				loading={loading}
			/>
			{trends?.visible === 'content-title' ? (
				<TrendIcon
					direction={trends?.direction}
					query={query}
					postfix={translationOptions?.postfix}
					data={translationOptions?.data}
				/>
			) : null}
		</Group>
	);
});

ContentTitle.propTypes = {
	config: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
		PropTypes.node,
	]),
};

/** @type { React.FC<ChildrenOptions & { config: ConfigValue }> } */
const ContentLoading = withConfig(function ContentLoading(props) {
	const { translationOptions, show } = props;

	return (
		<Translation
			translationKey="card.content.loading"
			translationOptions={translationOptions}
			showWhenEmpty={false}
			Component={BaseText}
			show={show}
		/>
	);
});

ContentLoading.propTypes = {
	config: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
		PropTypes.node,
	]),
};

/** @type { React.FC<ChildrenOptions & { config: ConfigValue }> } */
const ContentText = withConfig(function ContentText(props) {
	const { config, ...args } = props;
	const { translationOptions, loading } = args;

	return (
		<Translation
			translationKey="card.content.text"
			show={config}
			translationOptions={translationOptions}
			showWhenEmpty={false}
			Component={BaseText}
			loading={loading}
		/>
	);
});

ContentText.propTypes = {
	config: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
		PropTypes.node,
	]),
};

export { ContentLoading, ContentSubtitle, ContentText, ContentTitle };
