import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import ActionContext from '../../actionContext';
import { useCardPinning } from '../../hooks';

/**
 * @typedef { import('./types').PinButtonProps } PinButtonProps
 */

/** @type { React.FC<PinButtonProps> } */
const PinButton = React.memo(function PinButton({ type, ...args }) {
	const { onAction } = React.useContext(ActionContext);
	const pinned = useCardPinning({ type: type });

	const onClick = React.useCallback(
		() => onAction?.('card:pin', { type }),
		[onAction, type],
	);

	return (
		<Button
			size="sm"
			icon={pinned ? 'pin-straight' : 'pin-rotated'}
			onClick={onClick}
			analyticsKey={`card.${type}.pin`}
			{...args}
		/>
	);
});

PinButton.propTypes = { onAction: PropTypes.func, type: PropTypes.string };

export default PinButton;
