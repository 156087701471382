import React from 'react';

import { isPast, isThisMonth } from 'date-fns';
import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';

import Health, { useHealthState } from '../health';
import Trends from '../trends';

import './styles.scss';

/**
 * @typedef { import('./types').Props } Props
 */

function useDateVariant($date) {
	const date = parseDate($date);

	if (isThisMonth(date)) {
		return 'today';
	}

	if (isPast(date)) {
		return 'past';
	}

	return 'future';
}

/** @type { React.FC<Props> } */
const Month = React.memo(function Month(props) {
	const {
		className,

		date,
		flat,

		onAction,

		balance,
		health,
		trends,

		loading,
		active,
	} = props;

	const dateVariant = useDateVariant(date);

	const healthState = useHealthState(health?.score);

	const extra = React.useMemo(
		() => ({
			data: { balance, health, date },
			postfix: {
				past: dateVariant === 'past',
				today: dateVariant === 'today',
				future: dateVariant === 'future',

				flat: flat,
				health: healthState,
				direction: trends?.direction,
			},
		}),
		[
			balance,
			date,
			dateVariant,
			flat,
			health,
			healthState,
			trends?.direction,
		],
	);

	const click = React.useCallback(
		() => onAction?.('time:selector:select', { date }),
		[date, onAction],
	);

	return (
		<div
			className={cn(
				'inline-flex cursor-pointer flex-col items-center justify-center gap-2 rounded border border-solid',
				'bg-time-selector-month-normal-background border-time-selector-month-normal-border',
				'hover:bg-time-selector-month-hover-background hover:border-time-selector-month-hover-border',
				{
					'bg-time-selector-month-focus-background border-time-selector-month-focus-border hover:bg-time-selector-month-focus-background hover:border-time-selector-month-focus-border':
						active,
				},
				'asteria-component__time-selector-month',
				stateClasses({ active: active }),
				{ 'asteria--variant-flat': flat },
				{
					[`asteria--state-health-${healthState}`]: healthState,
					[`asteria--state-trends-${trends?.direction}`]:
						trends?.direction,
				},
				className,
			)}
			onClick={click}
		>
			<Health
				className="asteria-component__time-selector-month-health"
				score={health?.score}
				flat={flat}
				extra={extra}
				loading={loading}
			/>
			<div
				className={cn(
					'flex items-center justify-center gap-2',
					'asteria-component__time-selector-month-balance',
				)}
			>
				<Translation
					translationKey="time.selector.month.balance"
					translationOptions={extra}
					Component={Title}
					loading={loading}
				/>
				<Trends
					className="asteria-component__time-selector-month-trends"
					direction={trends?.direction}
					extra={extra}
					size="sm"
				/>
			</div>
			<Translation
				translationKey="time.selector.month.label"
				translationOptions={extra}
				Component={Text}
			/>
		</div>
	);
});

Month.displayName = 'Month';

Month.propTypes = {
	className: PropTypes.string,

	date: PropTypes.string,
	flat: PropTypes.bool,
	onAction: PropTypes.func,

	balance: PropTypes.shape({ value: PropTypes.number }),
	health: PropTypes.shape({ score: PropTypes.number }),
	trends: PropTypes.shape({ direction: PropTypes.oneOf(['up', 'down']) }),

	loading: PropTypes.bool,
	active: PropTypes.bool,
};

export default Month;
