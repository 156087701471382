import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';
import Header from '@asteria/component-core/wrapper/header';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import TrendIcon from '../../components/trends';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 * @typedef { import('./types').ConfigValue } ConfigValue
 */

/** @type { React.FC<Props> } */
const CardHeader = React.memo(function CardHeader(props) {
	const {
		className,
		postfix,
		trends,
		query,
		loading,
		translationOptions,
		children,
	} = props;

	return (
		<Header
			postfix={postfix}
			className={cn('asteria-component__card-header', className)}
		>
			{children ? (
				children
			) : (
				<Group direction="horizontal" verticalAlign="center">
					<Translation
						translationKey="card.title"
						translationOptions={translationOptions}
						Component={Title}
					/>
					{!loading && trends?.visible === 'header' ? (
						<TrendIcon
							direction={trends?.direction}
							query={query}
							postfix={translationOptions.postfix}
							data={translationOptions.data}
						/>
					) : null}
				</Group>
			)}
		</Header>
	);
});

CardHeader.displayName = 'CardHeader';

CardHeader.propTypes = {
	className: PropTypes.string,

	query: PropTypes.object,

	loading: PropTypes.bool,
	trends: PropTypes.shape({
		visible: PropTypes.string,
		direction: PropTypes.string,
	}),
	direction: PropTypes.string,
	postfix: PropTypes.shape({}),
	translationOptions: PropTypes.shape({
		postfix: PropTypes.shape({}),
		data: PropTypes.shape({}),
	}),
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default CardHeader;
