import React from 'react';

import PropTypes from 'prop-types';

import { TranslationService } from '@asteria/language';

import ActionContext from '../../actionContext';
import PromotionCard from '../../cards/promotion';
import {
	onPromotionAction,
	promotionInitialState,
	promotionReducer,
} from '../../cards/promotion/handlers';
import {
	useCardPinning,
	useCardVisibility,
	useDateVariant,
	useOnboardingState,
	useTranslationData,
	useTrendDirection,
} from '../../hooks';

import Postfix from './postfix';
import CardView from './view';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 * @typedef { import('./types').ConfigValue } ConfigValue
 */

/** @type { React.FC<Props> } */

const Card = React.memo(function Card(props) {
	const {
		className,
		show = true,
		type,
		query,

		startDate,
		endDate,

		onAction,
		onSubmit,
		children,
		config,
		integrations,
		extra,

		connect,

		usePromotionVisibility,
	} = props;

	const configVisible = config?.visible;
	const configPromotion = config?.promotion ?? true;
	const configTrends = config?.trends ?? 'content-title';

	const cardVisible = useCardVisibility({ type: type });
	const pinned = useCardPinning({ type: type });

	const [state, dispatch] = React.useReducer(
		(state, action) => {
			const promotionState = promotionReducer(state, action);

			switch (action?.type) {
				case 'SET_VISIBLE_CARD':
					return {
						...promotionState,
						visible: {
							...promotionState.visible,
							card: action?.payload,
						},
					};

				default:
					return promotionState;
			}
		},
		{
			...promotionInitialState,
			visible: { card: configVisible ?? cardVisible },
		},
	);

	React.useEffect(() => {
		if (configVisible !== undefined) {
			dispatch({ type: 'SET_VISIBLE_CARD', payload: configVisible });
		}
	}, [configVisible]);

	const variant = useDateVariant({ startDate, endDate });

	const postfix = React.useMemo(
		() => ({
			children: {
				after: (
					<Postfix
						type={type}
						startDate={startDate}
						endDate={endDate}
						onAction={onAction}
					/>
				),
			},
		}),
		[endDate, onAction, startDate, type],
	);

	const onboarding = useOnboardingState();

	const direction = useTrendDirection({
		card: type,
		type: variant,
		query: query,
	});

	const { postfix: translationPostfix, data: translationData } =
		useTranslationData({
			type: type,

			query: query,
			trends: direction,

			past: variant === 'past',
			today: variant === 'today',
			future: variant === 'future',

			startDate,
			endDate,

			extra: extra,
		});

	const translationOptions = React.useMemo(
		() => ({
			data: translationData,
			postfix: translationPostfix,
		}),
		[translationData, translationPostfix],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			return onPromotionAction?.(action, data, {
				dispatch,
				onAction,
				required: integrations?.required,
			});
		},
		[integrations?.required, onAction],
	);

	const handleSubmit = React.useCallback(
		(action, data) => {
			return onSubmit?.(action, data);
		},
		[onSubmit],
	);

	const loading =
		(query?.isFetching && !query?.isRefetching) || integrations?.initiating;

	const isPromotionVisibleByDefault =
		configPromotion &&
		((integrations?.required !== undefined && !integrations?.required) ||
			state?.promotion?.dismiss?.visible ||
			state?.promotion?.feedback?.visible);

	const isPromotionVisible =
		usePromotionVisibility?.({
			loading,
			variant,
			query,
			onboarding,
			direction,
			translationOptions,
			startDate,
			endDate,

			onAction: handleAction,
			onSubmit: handleSubmit,

			default: isPromotionVisibleByDefault,
		}) ?? isPromotionVisibleByDefault;

	const actionContext = React.useMemo(() => {
		return {
			onSubmit: handleSubmit,
			onAction: handleAction,
		};
	}, [handleSubmit, handleAction]);

	const childrenProps = {
		loading: loading,
		variant: variant,
		integrations: integrations,
		query: query,
		onboarding: onboarding,
		translationOptions: translationOptions,
		startDate: startDate,
		endDate: endDate,
		trends: {
			visible: configTrends,
			direction: direction,
		},
		state,
		onAction: handleAction,
		onSubmit: handleSubmit,
	};

	if (!show) {
		return null;
	}

	return (
		<ActionContext.Provider value={actionContext}>
			{!isPromotionVisible ? (
				<CardView
					className={className}
					pinned={pinned}
					loading={loading}
					type={type}
				>
					{typeof children === 'function'
						? children(childrenProps)
						: children}
				</CardView>
			) : (
				<PromotionCard
					type={type}
					startDate={startDate}
					endDate={endDate}
					onSubmit={handleSubmit}
					onAction={handleAction}
					postfix={postfix}
					state={state}
					wrapper={props?.wrapper}
					show={state?.visible?.card}
					connect={connect}
					card={{
						title: TranslationService.getV2(
							['card.title'],
							translationOptions,
						),
					}}
				/>
			)}
		</ActionContext.Provider>
	);
});

Card.displayName = 'Card';

Card.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,

	type: PropTypes.string,
	query: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),

	integrations: PropTypes.shape({
		required: PropTypes.bool,
		optional: PropTypes.bool,
		initiating: PropTypes.bool,
	}),

	extra: PropTypes.shape({
		postfix: PropTypes.object,
		data: PropTypes.object,
	}),

	connect: PropTypes.string,
	config: PropTypes.shape({
		content: PropTypes.bool,

		subtitle: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),
		title: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),
		loading: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),
		text: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),

		trends: PropTypes.bool,
		promotion: PropTypes.bool,
		visible: PropTypes.bool,
	}),

	usePromotionVisibility: PropTypes.func,
};

export default Card;
