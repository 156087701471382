import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Card from '../../base';

import CardFeedbackForm from './components/card';
import CardDismissForm from './components/dismiss';
import FeedbackSuccess from './components/success';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { string } [from]
 * @property { number } step
 * @property { boolean } rating
 * @property { unknown } data
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 * @property { () => void } onBack
 * @property { () => void } onClose
 * @property { (form: unknown) => void } onSend
 *
 * @typedef Props
 * @property { string } className
 * @property { string } from
 * @property { string } startDate
 * @property { string } endDate
 * @property { boolean } rating
 * @property { 'dismiss' | 'card'} variant
 * @property { unknown } data
 * @property { unknown } card
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<Props> } */
const FeedbackCard = React.memo(function FeedbackCard(props) {
	const {
		className,
		from,
		variant = 'dismiss',
		onAction,
		onSubmit,
		rating,
		startDate,
		endDate,
		data,
		children,
		card,
	} = props;

	const [step, setStep] = React.useState(0);

	const integrations = useSelector(
		IntegrationStore.selectors.integrations,
		(a, b) => isEqual(a, b),
	);

	const onboardingData = React.useMemo(() => {
		return integrations.reduce((acc, object) => {
			return {
				...acc,
				[object._id]: {
					status: object?.status?.state,
					disabled: object?.disabled,
					connected: object?.config?.connected,
					lastSync: object?.lastSync,
				},
			};
		}, {});
	}, [integrations]);

	const back = useMutation({
		mutationFn: async () =>
			onAction?.('card:back', {
				type: 'feedback',
				variant: variant,
				from: from,
				data: data,
			}),
	});

	const close = useMutation({
		mutationFn: async () =>
			onAction?.('card:close', {
				type: 'feedback',
				variant: variant,
				from: from,
				data: { ...data, step: step },
			}),
	});

	const send = useMutation({
		mutationFn: async (form) =>
			onSubmit?.('card:submit', {
				type: 'feedback',
				from: from,
				variant: variant,
				form: form,
				data: data,
				startDate: startDate,
				endDate: endDate,
				onboarding: onboardingData,
			}),
		onSuccess: (_, form) => {
			if (form?.dismiss) {
				return close.mutate();
			}

			setStep((step) => step + 1);
		},
	});

	const onClose = React.useCallback(
		() => send.mutate({ dismiss: true }),
		[send],
	);

	const handleBack = React.useCallback(() => {
		if (step === 0) {
			return back.mutate();
		}

		return;
	}, [back, step]);

	const handleClose = React.useCallback(() => {
		if (step === 0) {
			if (variant === 'dismiss') {
				return onClose?.();
			}

			return back.mutate();
		}

		if (step === 1) {
			return close.mutate();
		}
	}, [back, close, onClose, step, variant]);

	return (
		<Form
			onSubmit={send.mutate}
			defaultValues={{
				rating: data?.rating ?? rating,
			}}
		>
			<Card className={cn('asteria--variant-feedback', className)}>
				<Card.Header
					verticalAlign="center"
					onBack={handleBack}
					onClose={handleClose}
				>
					{TranslationService.getV2(['card.title'], {
						postfix: {
							variant: 'feedback',
							from: from,
							step: step + 1,
							feedback: variant,
						},
						data: { card: card },
					})}
				</Card.Header>
				<Card.Content>
					{step === 0 && variant === 'dismiss' ? (
						<CardDismissForm
							from={from}
							step={step}
							rating={rating}
							card={card}
						/>
					) : null}
					{step === 0 && variant === 'card' ? (
						<CardFeedbackForm from={from} step={step} card={card} />
					) : null}
					{step === 1 ? (
						<FeedbackSuccess from={from} step={step} card={card} />
					) : null}
					{typeof children === 'function'
						? children({
								from: from,
								step: step + 1,
								rating,
								data,

								onAction,
								onSubmit,

								onBack: back.mutate,
								onClose: close.mutate,
								onSend: send.mutate,

								card: card,
						  })
						: children}
				</Card.Content>
				<Card.Footer>
					{step === 0 ? (
						<FooterSection position="first">
							<Button
								variant="secondary"
								analyticsKey="card.feedback.action.back"
								label={TranslationService.getV2(
									['card.action'],
									{
										postfix: {
											variant: 'feedback',
											action: 'back',
											from: from,
											step: step + 1,
											feedback: variant,
										},
										data: { card: card },
									},
								)}
								onClick={handleBack}
							/>
						</FooterSection>
					) : null}

					<FooterSection position="last">
						{step === 0 ? (
							<Button
								variant="primary"
								analyticsKey="card.feedback.action.send"
								label={TranslationService.getV2(
									['card.action'],
									{
										postfix: {
											variant: 'feedback',
											action: 'send',
											from: from,
											step: step + 1,
											feedback: variant,
										},
										data: { card: card },
									},
								)}
								type="submit"
								loading={send.isLoading}
								disabled={send.isLoading}
							/>
						) : null}
						{step === 1 ? (
							<Button
								variant="primary"
								onClick={close.mutate}
								analyticsKey="card.feedback.action.close"
								label={TranslationService.getV2(
									['card.action'],
									{
										postfix: {
											variant: 'feedback',
											action: 'close',
											from: from,
											step: step + 1,
											feedback: variant,
										},
										data: { card: card },
									},
								)}
							/>
						) : null}
					</FooterSection>
				</Card.Footer>
			</Card>
		</Form>
	);
});

FeedbackCard.displayName = 'FeedbackCard';

FeedbackCard.propTypes = {
	className: PropTypes.string,
	from: PropTypes.string,
	rating: PropTypes.bool,
	variant: PropTypes.oneOf(['dismiss', 'card']),

	data: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	card: PropTypes.object,
};

export default FeedbackCard;
