import React from 'react';

import { useQueries } from '@tanstack/react-query';
import { formatISO, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Tooltip from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';

import TrendIcon from '../../components/trends';
import { useInitiatingIntegration, useRequiredIntegrations } from '../../hooks';
import BasicCard from '../basic';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'paid' | 'today' | 'future' } variant
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { 'customer' | 'supplier' } clientType
 * @property { 'up' | 'down' } direction
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { 'customer' | 'supplier' } clientType
 * @property { string } endDate
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

function useRequest({ onSubmit, startDate, endDate, clientType }) {
	return useQueries({
		queries: [
			{
				queryKey: [
					'card',
					`${clientType}-performance`,
					{ startDate, endDate },
				],
				queryFn: async () => {
					return await onSubmit?.('card:fetch', {
						type: `${clientType}-performance`,
						source: {
							startDate: startDate,
							endDate: endDate,
						},
						target: {
							startDate: formatISO(
								subMonths(parseDate(startDate), 1),
								{ representation: 'date' },
							),
							endDate: formatISO(
								subMonths(parseDate(endDate), 1),
								{ representation: 'date' },
							),
						},
					});
				},

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				keepPreviousData: true,
			},
			{
				queryKey: ['card', 'account', { startDate, endDate }],
				queryFn: async () => {
					return await onSubmit?.('card:fetch', {
						type: 'account',
						source: {
							startDate: startDate,
							endDate: endDate,
						},
						target: {
							startDate: formatISO(
								subMonths(parseDate(startDate), 1),
								{ representation: 'date' },
							),
							endDate: formatISO(
								subMonths(parseDate(endDate), 1),
								{ representation: 'date' },
							),
						},
					});
				},

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				keepPreviousData: true,

				enabled: !!startDate && !!endDate,
			},
		],
	});
}

function getTrendDirection(data, clientId) {
	const source = data?.source?.[clientId] ?? 0;
	const target = data?.target?.[clientId] ?? 0;

	if (source === target) {
		return 'equal';
	}

	if (source > target) {
		return 'up';
	}

	return 'down';
}

/**
 * @type { React.FC<ChildrenOptions> }
 */
const ClientPerformanceExtra = React.memo(function ClientPerformanceExtra(
	props,
) {
	const {
		query,
		translationOptions,
		clientType = 'customer',
		onAction,
	} = props;

	const showClient = React.useCallback(
		(client) =>
			onAction?.('card:action', {
				type: `${clientType}-details`,
				client: client,
			}),
		[clientType, onAction],
	);

	const sourceDetails = (
		query?.data?.source?.statistics?.details ?? []
	).reduce((acc, { clientId, count }) => ({ ...acc, [clientId]: count }), {});

	const targetDetails = (
		query?.data?.target?.statistics?.details ?? []
	).reduce((acc, { clientId, count }) => ({ ...acc, [clientId]: count }), {});

	const hasAccuracy = (query?.data?.source?.statistics?.details ?? []).some(
		(object) => (object?.accuracy ?? null) !== null,
	);

	return (
		<div className="asteria-component__client-statistics">
			<div className="grid grid-cols-2">
				{query?.data?.source?.statistics?.best?.total?.actual ? (
					<div className="asteria-component__client-box">
						<Translation
							translationKey="card.content.client.best"
							translationOptions={translationOptions}
							Component={Text}
						/>
						<Button
							size="sm"
							tooltip={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-best',
								},
								data: query?.data?.source?.statistics?.best,
							})}
							variant="link"
							label={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-best',
								},
								data: query?.data?.source?.statistics?.best,
							})}
							onClick={() =>
								showClient(
									query?.data?.source?.statistics?.best,
								)
							}
						/>
						<Translation
							translationKey="card.content.client.best.total"
							translationOptions={translationOptions}
							Component={Title}
							size="xs"
						/>
					</div>
				) : null}
				{query?.data?.source?.statistics?.worst?.total?.actual ? (
					<div className="asteria-component__client-box">
						<Translation
							translationKey="card.content.client.worst"
							translationOptions={translationOptions}
							Component={Text}
						/>
						<Button
							size="sm"
							variant="link"
							tooltip={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-worst',
								},
								data: query?.data?.source?.statistics?.worst,
							})}
							label={TranslationService.getV2(['card.action'], {
								postfix: {
									type: 'client-details',
									variant: `${clientType}-performance`,
									action: 'open-worst',
								},
								data: query?.data?.source?.statistics?.worst,
							})}
							onClick={() =>
								showClient(
									query?.data?.source?.statistics?.worst,
								)
							}
						/>
						<Translation
							translationKey="card.content.client.worst.total"
							translationOptions={translationOptions}
							Component={Title}
							size="xs"
						/>
					</div>
				) : null}
			</div>
			<div
				className={cn('asteria-component__client-performance-table', {
					'asteria--state-accuracy': hasAccuracy,
				})}
			>
				{query?.data?.source?.statistics?.details
					?.slice(0, 5)
					?.map((object, index) => {
						const sourceCount = sourceDetails?.[object?.clientId];
						const targetCount = targetDetails?.[object?.clientId];

						const trends = getTrendDirection(
							{
								source: sourceDetails,
								target: targetDetails,
							},
							object?.clientId,
						);

						return (
							<>
								<Translation
									translationKey="card.content.client.table"
									translationOptions={{
										data: { value: index + 1 },
										postfix: {
											value: 'index',
											variant: `${clientType}-performance`,
										},
									}}
									Component={Text}
									size="sm"
								/>
								<TrendIcon
									size="xs"
									direction={trends}
									query={query}
									postfix={{
										...translationOptions?.postfix,
										type: 'client-table',
										trends: trends,
									}}
									data={{
										...translationOptions?.data,
										trends: {
											count: {
												source: sourceCount,
												target: targetCount,
											},
											percentage: targetCount
												? (Math.abs(
														sourceCount -
															targetCount,
												  ) /
														targetCount) *
												  100
												: 100,
										},
									}}
								/>
								<Button
									size="sm"
									variant="link"
									label={TranslationService.getV2(
										['card.action'],
										{
											postfix: {
												type: 'client-details',
												variant: `${clientType}-performance`,
												action: 'open-client',
											},
											data: object,
										},
									)}
									tooltip={TranslationService.getV2(
										['card.action'],
										{
											postfix: {
												type: 'client-details',
												variant: `${clientType}-performance`,
												action: 'open-client',
											},
											data: object,
										},
									)}
									onClick={() => showClient(object)}
								/>
								<Tooltip>
									<Text className="text-end" size="sm">
										{TranslationService.getV2(
											['card.content.client.table'],
											{
												postfix: {
													value: 'impact',
													variant: `${clientType}-performance`,
												},
												data: object,
											},
										)}
									</Text>
									<Translation
										translationKey="card.content.client.table"
										translationOptions={{
											data: object,
											postfix: {
												value: 'impact',
												type: 'tooltip',
												variant: `${clientType}-performance`,
											},
										}}
										Component={Text}
										size="sm"
									/>
								</Tooltip>
								{hasAccuracy ? (
									<Tooltip>
										<Text className="text-end" size="sm">
											{TranslationService.getV2(
												['card.content.client.table'],
												{
													postfix: {
														value: 'accuracy',
														variant: `${clientType}-performance`,
													},
													data: object,
												},
											)}
										</Text>
										<Translation
											className="text-end"
											translationKey="card.content.client.table"
											translationOptions={{
												data: object,
												postfix: {
													value: 'accuracy',
													variant: `${clientType}-performance`,
													type: 'tooltip',
												},
											}}
											Component={Text}
											size="sm"
										/>
									</Tooltip>
								) : null}

								<div className="flex flex-col items-end">
									<Translation
										translationKey="card.content.client.table"
										translationOptions={{
											data: object,
											postfix: {
												value: 'actual',
												variant: `${clientType}-performance`,
											},
										}}
										Component={Title}
										size="xxs"
									/>
									{(object?.total?.forecasted ?? null) !==
									null ? (
										<Translation
											translationKey="card.content.client.table"
											translationOptions={{
												data: object,
												postfix: {
													value: 'forecasted',
													variant: `${clientType}-performance`,
												},
											}}
											Component={Text}
											size="sm"
										/>
									) : null}
								</div>
							</>
						);
					})}
			</div>
		</div>
	);
});

ClientPerformanceExtra.propTypes = {
	loading: PropTypes.bool,
	variant: PropTypes.string,
	query: PropTypes.object,
	clientType: PropTypes.oneOf('customer', 'supplier'),
	translationOptions: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const CONNECT = { type: 'erp', onboarding: 'bank' };

/** @type { React.FC<Props> } */
const ClientPerformanceCard = React.memo(function ClientPerformanceCard(props) {
	const { startDate, endDate, onSubmit, clientType = 'customer' } = props;

	const queries = useRequest({
		onSubmit,
		startDate,
		endDate,
		clientType,
	});

	const { required, optional } = useRequiredIntegrations({
		required: 'erp',
	});

	const initiating = useInitiatingIntegration({ type: 'erp' });

	const config = React.useMemo(() => {
		const totalValue = queries?.[0]?.data?.source?.invoices?.total?.actual;

		return { subtitle: !!totalValue, title: !!totalValue };
	}, [queries]);

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	const sourceCount = queries?.[0]?.data?.source?.invoices?.count ?? 0;
	const targetCount = queries?.[0]?.data?.target?.invoices?.count ?? 0;

	return (
		<BasicCard
			{...props}
			type={`${clientType}-performance`}
			query={queries?.[0]}
			integrations={integrations}
			config={config}
			extra={{
				data: {
					account: queries?.[1]?.data,
					trends: {
						count: { source: sourceCount, target: targetCount },
						percentage: targetCount
							? (Math.abs(sourceCount - targetCount) /
									targetCount) *
							  100
							: 100,
					},
				},
			}}
			connect={CONNECT}
		/>
	);
});

ClientPerformanceCard.displayName = 'ClientPerformanceCard';

ClientPerformanceCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	clientType: PropTypes.oneOf(['customer', 'supplier']),

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default ClientPerformanceCard;

export { ClientPerformanceExtra };
