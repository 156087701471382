import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { TextGroup, Title } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Card from '../../base';
import TrendIcon from '../../components/trends';
import Wrapper from '../../components/wrapper';
import {
	useCardPinning,
	useCardVisibility,
	useDateVariant,
	useOnboardingState,
	useTranslationData,
	useTrendDirection,
} from '../../hooks';
import PromotionCard from '../promotion';
import {
	onPromotionAction,
	promotionInitialState,
	promotionReducer,
} from '../promotion/handlers';
import PromotionFooter from '../promotion/partial';

import {
	ContentLoading,
	ContentSubtitle,
	ContentText,
	ContentTitle,
} from './content';
import FeedbackButtons from './feedback';
import Postfix from './postfix';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 * @typedef { import('./types').ConfigValue } ConfigValue
 */

/** @type { React.FC<Props> } */
const BasicCard = React.memo(function BasicCard(props) {
	const {
		className,

		type,
		query,

		startDate,
		endDate,

		onAction,
		onSubmit,
		children,
		config,
		integrations,
		extra,

		connect,

		usePromotionVisibility,
		experimental,
	} = props;

	const configContent = config?.content ?? true;
	const configSubtitle = config?.subtitle ?? false;
	const configTitle = config?.title ?? true;
	const configLoading = config?.loading ?? true;
	const configText = config?.text ?? true;
	const configVisible = config?.visible;
	const configTrends = config?.trends ?? 'content-title';
	const configPromotion = config?.promotion ?? true;

	const WrapperComponent = props?.wrapper?.as ?? Wrapper;

	const cardVisible = useCardVisibility({ type: type });
	const pinned = useCardPinning({ type: type });

	const hasExperimentalFeature = useFeature('streamline-experimental');

	const [state, dispatch] = React.useReducer(
		(state, action) => {
			const promotionState = promotionReducer(state, action);

			switch (action?.type) {
				case 'SET_VISIBLE_CARD':
					return {
						...promotionState,
						visible: {
							...promotionState.visible,
							card: action?.payload,
						},
					};

				default:
					return promotionState;
			}
		},
		{
			...promotionInitialState,
			visible: { card: configVisible ?? cardVisible },
		},
	);

	React.useEffect(() => {
		if (configVisible !== undefined) {
			dispatch({ type: 'SET_VISIBLE_CARD', payload: configVisible });
		}
	}, [configVisible]);

	const variant = useDateVariant({ startDate, endDate });

	const onboarding = useOnboardingState();

	const direction = useTrendDirection({
		card: type,
		type: variant,
		query: query,
	});

	const { postfix: translationPostfix, data: translationData } =
		useTranslationData({
			type: type,

			query: query,
			trends: direction,

			past: variant === 'past',
			today: variant === 'today',
			future: variant === 'future',

			startDate,
			endDate,

			extra: extra,
		});

	const translationOptions = React.useMemo(
		() => ({
			data: translationData,
			postfix: translationPostfix,
		}),
		[translationData, translationPostfix],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			return onPromotionAction?.(action, data, {
				dispatch,
				onAction,
				required: integrations?.required,
			});
		},
		[integrations?.required, onAction],
	);

	const handleSubmit = React.useCallback(
		(action, data) => {
			return onSubmit?.(action, data);
		},
		[onSubmit],
	);

	const loading =
		(query?.isFetching && !query?.isRefetching) || integrations?.initiating;

	const isPromotionVisibleByDefault =
		configPromotion &&
		((integrations?.required !== undefined && !integrations?.required) ||
			state?.promotion?.dismiss?.visible ||
			state?.promotion?.feedback?.visible);

	const isPromotionVisible =
		usePromotionVisibility?.({
			loading,
			variant,
			query,
			onboarding,
			direction,
			translationOptions,
			startDate,
			endDate,
			feedback: state?.promotion?.feedback?.visible,
			dismiss: state?.promotion?.dismiss?.visible,
			onAction: handleAction,
			onSubmit: handleSubmit,

			default: isPromotionVisibleByDefault,
		}) ?? isPromotionVisibleByDefault;

	const postfix = React.useMemo(
		() => ({
			children: {
				after: (
					<Postfix
						type={type}
						translationOptions={translationOptions}
						startDate={startDate}
						endDate={endDate}
						onAction={onAction}
					/>
				),
			},
		}),
		[endDate, onAction, startDate, translationOptions, type],
	);

	if (experimental && !hasExperimentalFeature) {
		return null;
	}

	if (isPromotionVisible) {
		return (
			<PromotionCard
				type={type}
				startDate={startDate}
				endDate={endDate}
				onSubmit={handleSubmit}
				onAction={handleAction}
				postfix={postfix}
				state={state}
				wrapper={props?.wrapper}
				show={state?.visible?.card}
				connect={connect}
				card={{
					title: TranslationService.getV2(
						['card.title'],
						translationOptions,
					),
				}}
			/>
		);
	}

	const childrenProps = {
		loading: loading,
		variant: variant,
		query: query,
		onboarding: onboarding,
		direction: direction,
		translationOptions: translationOptions,
		startDate: startDate,
		endDate: endDate,
		onAction: handleAction,
		onSubmit: handleSubmit,
	};

	return (
		<WrapperComponent {...props?.wrapper?.props} show={state.visible.card}>
			<Card
				className={cn(
					'asteria--variant-basic',
					{ [`asteria--variant-${type}`]: type },
					{ 'asteria--state-pinned': pinned },
					className,
				)}
				loading={loading}
			>
				<Card.Header postfix={postfix}>
					<Group direction="horizontal" verticalAlign="center">
						<Translation
							translationKey="card.title"
							translationOptions={translationOptions}
							Component={Title}
						/>
						{!integrations?.initiating &&
						configTrends === 'header' ? (
							<TrendIcon
								direction={direction}
								query={query}
								postfix={translationPostfix}
								data={translationData}
							/>
						) : null}
					</Group>
				</Card.Header>
				<Card.Content>
					{configContent ? (
						<TextGroup className="flex flex-col gap-2">
							<ContentSubtitle
								config={configSubtitle}
								{...childrenProps}
							/>
							<ContentTitle
								config={configTitle}
								trends={{
									visible: configTrends,
									direction: direction,
								}}
								{...childrenProps}
							/>
							<ContentLoading
								config={configLoading}
								{...childrenProps}
								show={integrations?.initiating}
							/>
							<ContentText
								config={configText}
								{...childrenProps}
							/>
						</TextGroup>
					) : null}
					{typeof children === 'function'
						? children(childrenProps)
						: children}
				</Card.Content>
				<PromotionFooter
					onAction={handleAction}
					onSubmit={handleSubmit}
					optional={integrations?.optional}
					required={integrations?.required}
					state={state}
				/>
				<FeedbackButtons type={type} onAction={handleAction} />
			</Card>
		</WrapperComponent>
	);
});

BasicCard.displayName = 'BasicCard';

BasicCard.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	query: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),

	integrations: PropTypes.shape({
		required: PropTypes.bool,
		optional: PropTypes.bool,
		initiating: PropTypes.bool,
	}),

	extra: PropTypes.shape({
		postfix: PropTypes.object,
		data: PropTypes.object,
	}),

	connect: PropTypes.string,
	config: PropTypes.shape({
		content: PropTypes.bool,

		subtitle: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),
		title: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),
		loading: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),
		text: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.func,
			PropTypes.node,
		]),

		trends: PropTypes.oneOf(['header', 'content-title']),
		promotion: PropTypes.bool,
		visible: PropTypes.bool,
	}),

	usePromotionVisibility: PropTypes.func,

	experimental: PropTypes.bool,
};

export default BasicCard;
