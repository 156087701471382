import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { formatISO, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { parseDate } from '@asteria/utils-funcs/normalize';

import { useInitiatingIntegration, useRequiredIntegrations } from '../../hooks';
import BasicCard from '../basic';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'paid' | 'today' | 'future' } variant
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { 'up' | 'down' } direction
 * @property { string } startDate
 * @property { string } endDate
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { string } endDate
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef ExtraSectionButtonProps
 * @property { 'customers' | 'suppliers' } section
 * @property { string } status
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { string } startDate
 * @property { string } endDate
 *
 * @typedef ExtraSectionProps
 * @property { string } section
 * @property { unknown } data
 * @property { string } startDate
 * @property { string } endDate
 * @property { boolean } show
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 *
 * @typedef ContentButtonProps
 * @property { string } status
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { { postfix: unknown, data: unknown } } translationOptions
 */

const STATUSES = [
	'summary',
	'paid',
	'overdue',
	'unpaid',
	'unsent',
	'credit',
	'forecast',
	'forecast_match',
];

function useRequest({ onSubmit, startDate, endDate }) {
	return useQuery({
		queryKey: ['card', 'invoices', { startDate, endDate }],
		queryFn: async () => {
			return await onSubmit?.('card:fetch', {
				type: 'invoices',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,
	});
}

/** @type { React.FC<ExtraSectionButtonProps> } */
const InvoiceCardExtraSectionButton = React.memo(
	function InvoiceCardExtraSectionButton({
		section,
		status,
		startDate,
		endDate,
		onAction,
		translationOptions,
	}) {
		const onClick = React.useCallback(
			() =>
				onAction?.('card:action', {
					type: 'invoices',
					section: section,
					status: status,
					startDate: startDate,
					endDate: endDate,
				}),
			[endDate, onAction, section, startDate, status],
		);

		return (
			<Button
				variant="link"
				label={TranslationService.getV2(['card.section.link'], {
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						section: section,
						link_type: status,
					},
				})}
				onClick={onClick}
			/>
		);
	},
);

InvoiceCardExtraSectionButton.propTypes = {
	section: PropTypes.string,
	status: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
};

/** @type { React.FC<ExtraSectionProps> } */
const InvoiceCardExtraSection = React.memo(function InvoiceCardExtraSection({
	section,
	data,
	show,
	startDate,
	endDate,
	translationOptions,
	onAction,
}) {
	if (!show) {
		return null;
	}

	return (
		<div className="flex flex-col">
			<Translation
				translationKey={['card.section.title']}
				translationOptions={{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						section: section,
					},
				}}
				Component={Text}
			/>
			{STATUSES.filter(
				(key) => !!data?.[key]?.count && key !== 'summary',
			).map((status) => (
				<InvoiceCardExtraSectionButton
					key={status}
					section={section}
					status={status}
					startDate={startDate}
					endDate={endDate}
					translationOptions={translationOptions}
					onAction={onAction}
				/>
			))}
		</div>
	);
});

InvoiceCardExtraSection.propTypes = {
	section: PropTypes.string,
	data: PropTypes.object,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	show: PropTypes.bool,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
};

/** @type { React.FC<Pick<ChildrenOptions, 'query' | 'translationOptions' | 'loading'>> } */
const InvoiceExtraActions = React.memo(function InvoiceExtraActions({
	query,
	translationOptions,
	loading,
}) {
	const actions = query?.data?.health?.actions ?? [];

	if (!actions?.length) {
		return null;
	}

	return (
		<TextGroup>
			{actions.map((action) => (
				<Translation
					key={action?.type}
					translationKey="card.section.hint"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							action: action?.type,
							...action?.postfix,
						},
						data: {
							...translationOptions?.data,
							action: action?.data,
							score: action?.score,
						},
					}}
					Component={Text}
					loading={loading}
				/>
			))}
		</TextGroup>
	);
});

InvoiceExtraActions.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
};

/** @type { React.FC<ChildrenOptions> } */
const InvoicesExtra = React.memo(function InvoicesExtra(props) {
	const {
		query,
		translationOptions,
		loading,
		startDate,
		endDate,
		onAction,
		onSubmit,
	} = props;

	return (
		<div className="flex flex-col gap-4">
			<InvoiceCardExtraSection
				section="customers"
				startDate={startDate}
				endDate={endDate}
				data={query?.data?.source?.customers}
				show={!!query?.data?.source?.customers?.summary?.count}
				translationOptions={translationOptions}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
			<InvoiceCardExtraSection
				section="suppliers"
				startDate={startDate}
				endDate={endDate}
				data={query?.data?.source?.suppliers}
				show={!!query?.data?.source?.suppliers?.summary?.count}
				translationOptions={translationOptions}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
			<InvoiceExtraActions
				query={query}
				translationOptions={translationOptions}
				loading={loading}
			/>
		</div>
	);
});

InvoicesExtra.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	loading: PropTypes.bool,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<ContentButtonProps> } */
const InvoicesContentTextButton = React.memo(
	function InvoicesContentTextButton({
		status,
		onAction,
		translationOptions,
	}) {
		const onClick = React.useCallback(
			() =>
				onAction?.('card:action', {
					type: 'invoices',
					section: 'both',
					status: status,
					startDate: translationOptions?.data?.startDate,
				}),
			[onAction, status, translationOptions?.data?.startDate],
		);

		return (
			<Button
				variant="link"
				label={TranslationService.getV2(['card.content.text.link'], {
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						link_type: status,
					},
				})}
				onClick={onClick}
			/>
		);
	},
);

InvoicesContentTextButton.propTypes = {
	status: PropTypes.string,
	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
};

/** @type { React.FC<ChildrenOptions> } */
const InvoicesContentText = React.memo(function InvoicesContentText(props) {
	const { query, translationOptions, onAction } = props;

	if (STATUSES.every((key) => !query?.data?.source?.[key]?.count)) {
		return (
			<Translation
				translationKey="card.content.text"
				translationOptions={{
					...translationOptions,
					postfix: { ...translationOptions.postfix, zero: true },
				}}
				Component={Text}
			/>
		);
	}

	return (
		<>
			<div className="flex flex-wrap gap-x-1">
				{STATUSES.filter(
					(key) => !!query?.data?.source?.[key]?.count,
				).map((status, index, arr) => (
					<div key={status} className="flex items-baseline">
						<InvoicesContentTextButton
							status={status}
							translationOptions={translationOptions}
							onAction={onAction}
						/>
						<Translation
							translationKey="card.content.text.link.separator"
							defaultText=","
							translationOptions={{
								...translationOptions,
								postfix: {
									...translationOptions?.postfix,
									link_type: status,
								},
							}}
							Component={Text}
							show={index !== arr.length - 1}
						/>
					</div>
				))}
			</div>
			{/* <Translation
				translationKey="card.content.text"
				translationOptions={translationOptions}
				showWhenEmpty={false}
				Component={Text}
				loading={loading}
			/> */}
		</>
	);
});

InvoicesContentText.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	loading: PropTypes.bool,
};

const CONFIG = {
	text: (args) => <InvoicesContentText {...args} />,
};

const CONNECT = { type: 'erp', onboarding: 'bank' };

/** @type { React.FC<Props> } */
const InvoicesCard = React.memo(function InvoicesCard(props) {
	const { startDate, endDate, onSubmit } = props;

	const query = useRequest({
		onSubmit,
		startDate,
		endDate,
	});

	const { required, optional } = useRequiredIntegrations({
		required: 'erp',
	});

	const initiating = useInitiatingIntegration({ type: 'erp' });

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	const paid = !!query?.data?.source?.paid?.count;
	const overdue = !!query?.data?.source?.overdue?.count;
	const unpaid = !!query?.data?.source?.unpaid?.count;
	const unsent = !!query?.data?.source?.unsent?.count;
	const credit = !!query?.data?.source?.credit?.count;
	const forecast = !!query?.data?.source?.forecast?.count;
	const forecast_match = !!query?.data?.source?.forecast_match?.count;

	const extra = React.useMemo(
		() => ({
			postfix: {
				paid: paid,
				overdue: overdue,
				unpaid: unpaid,
				unsent: unsent,
				credit: credit,
				forecast: forecast,
				forecast_match: forecast_match,
			},
		}),
		[credit, forecast, forecast_match, overdue, paid, unpaid, unsent],
	);

	return (
		<BasicCard
			{...props}
			type="invoices"
			query={query}
			integrations={integrations}
			extra={extra}
			config={CONFIG}
			connect={CONNECT}
		/>
	);
});

InvoicesCard.displayName = 'InvoicesCard';

InvoicesCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default InvoicesCard;
export { InvoicesExtra };
