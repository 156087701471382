import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import { FooterSection } from '@asteria/component-core/wrapper';

import { useFeature } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Card from '../../base';
import Wrapper from '../../components/wrapper';

import { useModalProperties } from './hooks';
import UnderConstructionModal from './modal';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { unknown } data
 * @property { unknown } postfix
 * @property { boolean | { title: string, content: unknown[] } } modal
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { unknown } data
 * @property { unknown } postfix
 * @property { boolean | { title: string, content: unknown[] } } modal
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { boolean} experimental
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<Props> } */
const UnderConstructionCard = React.memo(function UnderConstructionCard(props) {
	const {
		className,
		onAction,
		onSubmit,
		data,
		postfix,
		modal,
		children,
		experimental,
	} = props;

	const WrapperComponent = props?.wrapper?.as ?? Wrapper;

	const hasExperimentalFeature = useFeature('streamline-experimental');

	const [visible, setVisible] = React.useState(true);
	const [detailsModalState, detailsModalActions] = useModalProperties();

	const close = useMutation({
		mutationFn: async () =>
			onAction?.('card:close', {
				variant: 'under-construction',
				data: data,
			}),
		onSuccess: () => {
			setVisible(false);
		},
	});

	if (experimental && !hasExperimentalFeature) {
		return null;
	}

	return (
		<>
			<UnderConstructionModal
				open={detailsModalState}
				onClose={detailsModalActions.close}
				data={data}
				postfix={postfix}
				modal={modal}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
			<WrapperComponent {...props?.wrapper?.props} show={visible}>
				<Card
					className={cn(
						'asteria--variant-under-construction',
						className,
					)}
				>
					<Card.Header onClose={close.mutate} verticalAlign="center">
						<Translation
							translationKey="card.title"
							translationOptions={{
								postfix: {
									variant: 'under-construction',
									...postfix,
								},
								data: data,
							}}
							Component={Title}
						/>
					</Card.Header>
					<Card.Content>
						<Translation
							translationKey="card.content.title"
							translationOptions={{
								postfix: {
									variant: 'under-construction',
									...postfix,
								},
								data: data,
							}}
							showWhenEmpty={false}
							Component={Title}
						/>
						<Translation
							translationKey="card.content.text"
							translationOptions={{
								postfix: {
									variant: 'under-construction',
									...postfix,
								},
								data: data,
							}}
							showWhenEmpty={false}
							Component={Text}
						/>
						{typeof children === 'function'
							? children({
									data,
									postfix,
									modal,

									onAction,
									onSubmit,
							  })
							: children}
					</Card.Content>

					{modal ? (
						<Card.Footer>
							<FooterSection position="first">
								<Button
									variant="primary"
									label={TranslationService.getV2(
										['card.footer.action'],
										{
											postfix: {
												variant: 'under-construction',
												action: 'learn-more',
												...postfix,
											},
											data: data,
										},
									)}
									analyticsKey="card.under-construction.footer.action.learn-more"
									onClick={detailsModalActions.open}
								/>
							</FooterSection>
						</Card.Footer>
					) : null}
				</Card>
			</WrapperComponent>
		</>
	);
});

UnderConstructionCard.displayName = 'UnderConstructionCard';

UnderConstructionCard.propTypes = {
	className: PropTypes.string,

	data: PropTypes.object,
	postfix: PropTypes.object,

	modal: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.arrayOf(PropTypes.object),
		}),
	]),

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),

	experimental: PropTypes.bool,
};

export default UnderConstructionCard;
