import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
	addDays,
	addMonths,
	addWeeks,
	addYears,
	eachDayOfInterval,
	endOfWeek,
	format,
	isSameDay,
	isSameMonth,
	isToday,
	isWeekend,
	startOfMonth,
	startOfWeek,
	subDays,
	subMonths,
	subYears,
} from 'date-fns';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';
import DatePickerContentCell from '../DatePickerContentCell';
import DatePickerSlider from '../DatePickerSlider';
import {
	cleanValue,
	getLocale,
	getNextMonthDisabled,
	getNextYearDisabled,
	getPreviousMonthDisabled,
	getPreviousYearDisabled,
	getSelectedCSSClasses,
} from '../utils';

import './index.scss';

const DatePickerContentDayCalendar = (props) => {
	const {
		onSelect,
		value: propValue,
		defaultValue,
		previousDate,
		updateCurrentDate,

		isPastDisabled,
		isFutureDisabled,

		locale,

		startDate,
		endDate,
		disabledDates,
	} = props;

	const currentLocale = getLocale(locale);

	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const items = useMemo(() => {
		let dates = eachDayOfInterval({
			start: startOfMonth(value),
			end: addWeeks(startOfMonth(value), 5),
		});

		const startDate = dates[0];
		const endDate = dates.slice(-1)[0];

		const startWeekday =
			Number.parseInt(format(startDate, 'e', { locale: currentLocale })) -
			1;

		const endWeekday = Number.parseInt(
			format(endDate, 'e', { locale: currentLocale }),
		);

		if (startWeekday) {
			dates = [
				...new Array(startWeekday)
					.fill(null)
					.map((_, index) => subDays(startDate, index + 1))
					.reverse(),
				...dates,
			];
		}

		if (endWeekday !== 7) {
			dates = [
				...dates,
				...new Array(7 - endWeekday)
					.fill(null)
					.map((_, index) => addDays(endDate, index + 1)),
			];
		}

		return dates;
	}, [value, locale]);

	const days = useMemo(() => {
		return eachDayOfInterval({
			start: startOfWeek(new Date(), { locale: currentLocale }),
			end: endOfWeek(new Date(), { locale: currentLocale }),
		}).map((date) =>
			cleanValue(format(date, 'eeeeee', { locale: currentLocale })),
		);
	}, [locale]);

	const { previousYear, nextYear, previousMonth, nextMonth } = useMemo(
		() => ({
			previousYear: subYears(value, 1),
			nextYear: addYears(value, 1),
			previousMonth: subMonths(value, 1),
			nextMonth: addMonths(value, 1),
		}),
		[value],
	);

	const isPreviousYearDisabled = useMemo(
		getPreviousYearDisabled({
			previousDate: previousDate,
			previousYear: previousYear,
			isPastDisabled: isPastDisabled,
			startDate: startDate,
		}),
		[previousDate, previousYear, isPastDisabled],
	);

	const isPreviousMonthDisabled = useMemo(
		getPreviousMonthDisabled({
			previousDate: previousDate,
			previousMonth: previousMonth,
			isPastDisabled: isPastDisabled,
			startDate: startDate,
		}),
		[previousDate, previousMonth, isPastDisabled],
	);

	const isNextYearDisabled = useMemo(
		getNextYearDisabled({
			nextYear: nextYear,
			isFutureDisabled: isFutureDisabled,
			endDate: endDate,
		}),
		[nextYear, isFutureDisabled],
	);

	const isNextMonthDisabled = useMemo(
		getNextMonthDisabled({
			nextMonth: nextMonth,
			isFutureDisabled: isFutureDisabled,
			endDate: endDate,
		}),
		[nextMonth, isFutureDisabled],
	);

	const onValueChange = useCallback(
		(value) => {
			setValue(value);
			updateCurrentDate(value);
		},
		[value, previousDate],
	);

	return (
		<div className="asteria-component__datepicker__content__day">
			<div className="asteria-component__datepicker__content__slider__wrapper asteria-component__datepicker__content__slider__wrapper--split">
				<DatePickerSlider
					isPreviousDisabled={isPreviousYearDisabled}
					isNextDisabled={isNextYearDisabled}
					onPreviousClick={() => {
						onValueChange(previousYear);
					}}
					onNextClick={() => {
						onValueChange(nextYear);
					}}
					label={cleanValue(
						format(value, 'yyyy', { locale: currentLocale }),
					)}
				/>
				<DatePickerSlider
					isPreviousDisabled={isPreviousMonthDisabled}
					isNextDisabled={isNextMonthDisabled}
					onPreviousClick={() => {
						onValueChange(previousMonth);
					}}
					onNextClick={() => {
						onValueChange(nextMonth);
					}}
					label={cleanValue(
						format(value, 'MMMM', { locale: currentLocale }),
					)}
				/>
			</div>
			<div className="asteria-component__datepicker__content__day__header">
				{days.map((day) => (
					<div key={day}>
						<Text>{day}</Text>
					</div>
				))}
			</div>
			<div
				className={cn(
					'asteria-component__datepicker__content__day__calendar',
					'asteria-component__datepicker__content__calendar',
				)}
			>
				{items.map((date, index) => (
					<DatePickerContentCell
						key={date ? date.toISOString() : index}
						date={date}
						onSelect={onSelect}
						value={propValue}
						isPastDisabled={isPastDisabled}
						isFutureDisabled={isFutureDisabled}
						isThisDate={isToday(date)}
						className={cn(
							'asteria-component__datepicker__content__day__calendar__item',
							getSelectedCSSClasses({
								value: propValue,
								date: date,
								validate: isSameDay,
							}),
							{
								'asteria--date-holiday': isWeekend(date),
								'asteria--date-current-month': isSameMonth(
									date,
									value,
								),
							},
						)}
						format="d"
						startDate={startDate}
						endDate={endDate}
						disabledDates={disabledDates}
						locale={locale}
						month={startOfMonth(value)}
					/>
				))}
			</div>
		</div>
	);
};

DatePickerContentDayCalendar.propTypes = {
	defaultValue: PropTypes.instanceOf(Date),

	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	previousDate: PropTypes.instanceOf(Date),
	updateCurrentDate: PropTypes.func,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentDayCalendar.defaultProps = {};

export default DatePickerContentDayCalendar;
