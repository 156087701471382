import React from 'react';

import PropTypes from 'prop-types';

import { Wrapper } from '@asteria/component-form';

import { cn } from '@asteria/utils-funcs/classes';

//
import './styles.scss';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 * @typedef { import('./types').ConfigValue } ConfigValue
 */

/** @type { React.FC<Props> } */

const CardView = React.memo(function CardView(props) {
	const { className, type, loading, pinned, children } = props;

	const WrapperComponent = props?.wrapper?.as ?? Wrapper;

	return (
		<WrapperComponent
			className={cn(
				'asteria-component__card',
				'asteria--variant-basic',
				{ [`asteria--variant-${type}`]: type },
				{ 'asteria--state-pinned': pinned },
				className,
			)}
			loading={loading}
		>
			{children}
		</WrapperComponent>
	);
});

CardView.displayName = 'CardView';

CardView.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	loading: PropTypes.bool,
	pinned: PropTypes.bool,

	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default CardView;
