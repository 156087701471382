import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Form from '@asteria/component-form';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { Translation } from '@asteria/language';

import {
	useDateVariant,
	useInitiatingIntegration,
	useOnboardingState,
	useRequiredIntegrations,
} from '../../hooks';
import BasicCard from '../basic';

import ForecastActionsGroup from './components/actionsGroup';
import Help from './components/help';
import { useForecasterFuture, useRemoveMutation, useRequest } from './hooks';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'none' | 'bank' | 'erp' | 'both' } onboarding
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { { postfix: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

const FORM_RESET_OPTIONS = {
	keepDirtyValues: false,
	keepErrors: false,
	keepDirty: false,
	keepValues: false,
	keepDefaultValues: false,
	keepIsSubmitted: false,
	keepIsSubmitSuccessful: false,
	keepTouched: false,
	keepIsValid: false,
	keepSubmitCount: false,
};

/** @type { React.FC<ChildrenOptions> } */
const ForecastActionsExtra = React.memo(function ForecastActionsExtra({
	query,
	onboarding,
	translationOptions: $translationOptions,
	onAction,
	onSubmit,
}) {
	const forecasterQuery = useForecasterFuture({ onSubmit });

	const sourceScenarioData = useSelector(
		ScenarioStore.selectors.sourceScenarioData,
	);

	const translationOptions = React.useMemo(
		() => ({
			...$translationOptions,
			data: {
				...$translationOptions?.data,
				scenario: sourceScenarioData,
			},
		}),
		[$translationOptions, sourceScenarioData],
	);

	const processActions = React.useCallback((data = {}) => {
		if (!data?.actions) {
			return [];
		}

		return data?.actions;
	}, []);

	const actionsData = React.useMemo(() => {
		const processedActions = processActions(query?.data);

		const actions = processedActions.reduce((acc, action) => {
			const type = action?.extra?.type;
			if (!type) {
				return acc;
			}

			if (!acc[type]) {
				acc[type] = [];
			}

			acc[type].push(action);

			return acc;
		}, {});

		return actions;
	}, [processActions, query?.data]);

	const values = React.useMemo(
		() => (forecasterQuery.isFetching ? {} : forecasterQuery.data),
		[forecasterQuery.data, forecasterQuery.isFetching],
	);

	if (!actionsData?.deposit?.length && !actionsData?.withdraw?.length) {
		return null;
	}

	return (
		<Form
			className="flex flex-col gap-8"
			values={values}
			resetOptions={FORM_RESET_OPTIONS}
		>
			<ForecastActionsGroup
				key="deposit-actions"
				type="deposit"
				data={actionsData?.deposit}
				translationOptions={translationOptions}
				onAction={onAction}
			/>
			<ForecastActionsGroup
				key="withdraw-actions"
				type="withdraw"
				data={actionsData?.withdraw}
				translationOptions={translationOptions}
				onAction={onAction}
			/>
			{onboarding === 'both' ? (
				<Help translationOptions={translationOptions} />
			) : (
				<Translation
					translationOptions={translationOptions}
					translationKey="card.content.forecast-actions.footer"
					Component={Text}
				/>
			)}
		</Form>
	);
});

ForecastActionsExtra.propTypes = {
	loading: PropTypes.bool,
	query: PropTypes.object,
	onboarding: PropTypes.oneOf('none', 'bank', 'erp', 'both'),
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const ForecastActionsCard = React.memo(function ForecastActionsCard(props) {
	const { startDate, endDate, onAction, onSubmit } = props;

	const query = useRequest({ onSubmit });

	const removeMutation = useRemoveMutation({ onSubmit });

	const onboarding = useOnboardingState();

	const { required, optional } = useRequiredIntegrations({
		optional: 'both',
	});

	const initiating = useInitiatingIntegration({ type: 'both' });

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'forecast:actions:delete') {
				return removeMutation.mutate(data);
			}

			return onAction?.(action, data);
		},
		[onAction, removeMutation],
	);

	const config = React.useMemo(() => ({ trends: false }), []);

	const variant = useDateVariant({ startDate, endDate });

	if (variant === 'past') {
		return null;
	}

	if (onboarding === 'none') {
		return null;
	}

	if (!query?.data?.actions?.length) {
		return null;
	}

	return (
		<BasicCard
			{...props}
			type="forecast-actions"
			query={query}
			integrations={integrations}
			onAction={handleAction}
			onSubmit={onSubmit}
			config={config}
		/>
	);
});

ForecastActionsCard.displayName = 'ForecastActionsCard';

ForecastActionsCard.propTypes = {
	className: PropTypes.string,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default ForecastActionsCard;
export { ForecastActionsExtra };
