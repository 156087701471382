import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { TextGroup } from '@asteria/component-core/typography';
import {
	Text as BaseText,
	Title as BaseTitle,
} from '@asteria/component-core/typography';
import Content from '@asteria/component-core/wrapper/content';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import TrendIcon from '../../components/trends';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 * @typedef { import('./types').ConfigValue } ConfigValue
 */

/** @type { React.FC<Props> } */
const CardContent = React.memo(function CardContent(props) {
	const {
		translationOptions,
		loading,
		integrations,
		query,
		trends,
		children,
	} = props;

	return (
		<Content
			scroll={scroll}
			loading={loading}
			className={cn('asteria-component__card-content', props.className)}
		>
			{children ? (
				children
			) : (
				<TextGroup className="flex flex-col gap-2">
					<Translation
						translationKey="card.content.subtitle"
						translationOptions={translationOptions}
						showWhenEmpty={false}
						Component={BaseText}
						loading={loading}
					/>
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="left"
					>
						<Translation
							translationKey="card.content.title"
							translationOptions={translationOptions}
							showWhenEmpty={false}
							Component={BaseTitle}
							loading={loading}
						/>
						{!loading && trends?.visible === 'content-title' ? (
							<TrendIcon
								direction={trends.direction}
								query={query}
								postfix={translationOptions?.postfix}
								data={translationOptions?.data}
							/>
						) : null}
					</Group>
					<Translation
						translationKey="card.content.loading"
						translationOptions={translationOptions}
						showWhenEmpty={false}
						Component={BaseText}
						show={integrations?.initiating ?? false}
					/>
					<Translation
						translationKey="card.content.text"
						translationOptions={translationOptions}
						showWhenEmpty={false}
						Component={BaseText}
						loading={loading}
					/>
				</TextGroup>
			)}
		</Content>
	);
});

CardContent.displayName = 'CardContent';

CardContent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	type: PropTypes.string,

	loading: PropTypes.bool,
	integrations: PropTypes.shape({
		required: PropTypes.bool,
		optional: PropTypes.bool,
		initiating: PropTypes.bool,
	}),

	translationOptions: PropTypes.shape({
		postfix: PropTypes.shape({}),
		data: PropTypes.shape({}),
	}),

	trends: PropTypes.shape({
		visible: PropTypes.string,
		direction: PropTypes.string,
	}),

	query: PropTypes.shape({}),
};

export default CardContent;
