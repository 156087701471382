export function getCardTagFilter({ type, data }) {
	if (type === 'invoices') {
		return (
			data?.category?.name === '$invoices' &&
			['$customer', '$supplier'].includes(data?.name)
		);
	}

	if (type === 'salaries') {
		return (
			data?.category?.name === '$salaries' &&
			'$salary'.includes(data?.name)
		);
	}
}
