import React from 'react';

import PropTypes from 'prop-types';

import ActionContext from '../../actionContext';
import PromotionFooter from '../../cards/promotion/partial';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 * @typedef { import('./types').ConfigValue } ConfigValue
 */

/** @type { React.FC<Props> } */
const CardFooter = React.memo(function CardFooter(props) {
	const { integrations, state } = props;

	const { onAction, onSubmit } = React.useContext(ActionContext);

	return (
		<PromotionFooter
			onAction={onAction}
			onSubmit={onSubmit}
			optional={integrations?.optional}
			required={integrations?.required}
			state={state}
		/>
	);
});

CardFooter.displayName = 'CardFooter';

CardFooter.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	integrations: PropTypes.shape({
		required: PropTypes.bool,
		optional: PropTypes.bool,
		initiating: PropTypes.bool,
	}),

	state: PropTypes.shape({}),
};

export default CardFooter;
